import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import Layout from "../timewise-custom/components/App/Layout";
import Navbar from "../timewise-custom/components/App/Navbar";
import SEO from "../timewise-custom/components/App/SEO";
import FaqCallToAction from "../timewise-custom/components/CallToAction/FaqCallToAction";
import MainBanner from "../timewise-custom/components/Security/MainBanner";
import OurConcepts from "../timewise-custom/components/Security/OurConcepts";

const Home = () => {
  const { t } = useI18next();

  return (
    <Layout>
      <SEO title={t("navigation:security")} />
      <Navbar />
      <MainBanner />
      <OurConcepts />
      <FaqCallToAction />
    </Layout>
  );
};

export default Home;
