import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image/index";
import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import { markdownUnwrapper } from "../../../utils/markdown-unwrapper";

const OurSolutions = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: { fields: [frontmatter___de___priority], order: ASC }
        filter: { frontmatter: { de: { template: { eq: "security_topic" } } } }
      ) {
        edges {
          node {
            frontmatter {
              de {
                description
                title
                image {
                  childImageSharp {
                    fluid(maxWidth: 350, quality: 70) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              en {
                description
                title
              }
            }
          }
        }
      }
    }
  `);
  const { language } = useI18next();
  const features = markdownUnwrapper(data, language);

  return (
    <section className="solutions-area pt-100 pb-70">
      <div className="container">
        <div className="row">
          {features.map(({ title, description, image }, idx) => (
            <div className="col-sm-6" key={idx}>
              <div className="single-solutions-box">
                <div className="image icon">
                  <Img
                    alt={title}
                    style={{
                      height: "100%",
                      width: "100%",
                      maxHeight: 175,
                    }}
                    imgStyle={{
                      objectFit: "contain",
                    }}
                    fluid={image.childImageSharp.fluid}
                  />
                </div>
                <h3>{title}</h3>
                <p className="text-left">{description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurSolutions;
