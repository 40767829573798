import { graphql, Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image/index";
import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import ReactWOW from "react-wow";

const MainBanner = () => {
  const data = useStaticQuery(graphql`
    query {
      securityImage: file(relativePath: { eq: "security.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);
  const { t } = useI18next();

  return (
    <div className="main-banner-area">
      <div className="container">
        <div className="banner-area-content">
          <ReactWOW delay=".1s" animation="fadeInLeft">
            <h2>{t("security:subtitle")}</h2>
          </ReactWOW>

          <ReactWOW delay=".1s" animation="fadeInLeft">
            <p>{t("security:description")}</p>
          </ReactWOW>

          <ReactWOW delay=".1s" animation="fadeInRight">
            <div className="banner-btn">
              <Link to="/contact" className="default-btn">
                <i className="flaticon-web"></i>
                {t("navigation:contact")} <span></span>
              </Link>
            </div>
          </ReactWOW>

          <ReactWOW delay=".1s" animation="fadeInUp">
            <div className="d-flex justify-content-center">
              <Img
                style={{
                  height: 1200 / 3,
                  width: 1600 / 3,
                  maxWidth: "100%",
                  backgroundSize: "cover",
                }}
                fluid={data.securityImage.childImageSharp.fluid}
              />
            </div>
          </ReactWOW>
        </div>
      </div>
    </div>
  );
};

export default MainBanner;
